const EXCLUDED_KEYS = [
  "applicant_signature",
  "applicant_id_photo",
  "applicant_signature_photo",
  "applicant_id_uploaded_file",
  "applicant_signature_uploaded_file",
  "review_stage_applicant_signature_photo",
  "john_tongue_centre",
  "john_tonge_centre",
  "transfer_business_operator_qld_coroner",
  "qld_independent_doctor_name",
  "qld_independent_doctor_address",
  "qld_coroner",
  "qld_independent_doctor_phone",
  "accompanying_documents",
  "ashes_return_type_crematorium_instructions",
];

const EXCLUDED_SUB_KEYS = ["deceased_nearest_surviving _relative_instructions"];

const SPECIAL_KEYS = {
  RELATIONSHIPS: [
    "deceased_parents",
    "deceased_child",
    "deceased_children",
    "deceased_marriages",
    "deceased_registered_relationships",
    "nearest_surviving_relative",
  ],
  TABLES: ["deceased_death_not_natural_causes_causes"],
};

const EXCLUDED_SECTIONS = [
  "Welcome",
  "Declaration",
  "Declaration Instructions",
  "Estate Administration - Next Steps",
  "Signature Review",
  "Upload Signature",
  "View Documents",
  "Review",
];

/**
 * Processes each page of the survey.js and appends it to the container.
 *
 * @param {Object} survey - The main survey object containing pages to be processed.
 */
export default (survey) => {
  const checkForElement = setInterval(() => {
    const reviewPageContainer = document.querySelector(".review-content");

    if (reviewPageContainer) {
      if (survey && survey.pages) {
        survey.pages.forEach((page, index) =>
          processPage(survey, page, reviewPageContainer, index)
        );
      }
      clearInterval(checkForElement);
    }
  }, 500);
};

/**
 * Processes the survey object, creates a corresponding DOM element and appends it to the provided container.
 */
function processPage(survey, page, container, pageNumber) {
  if (!page.elements) return;

  const pageElementDiv = createPageElementDiv(survey, page, pageNumber);
  if (pageElementDiv) {
    container.appendChild(pageElementDiv);
  }
}

/**
 * Creates a wrapper div for a given page element, except for the excluded sections.
 * The wrapper div contains the page title, the page elements and an edit button.
 */
function createPageElementDiv(survey, page, pageNumber) {
  if (EXCLUDED_SECTIONS.includes(page.name)) {
    return null;
  }

  const wrapperDiv = createElement("div", "page-element");
  const title = createElement("h2");
  title.innerText = page.name;
  wrapperDiv.appendChild(title);

  if (page.elements) {
    page.elements.forEach((question) => {
      if (question.type === "paneldynamic") {
        return;
      }
      appendQuestion(survey, question, wrapperDiv);
    });
  }
  addEditButton(survey, wrapperDiv, pageNumber);

  return wrapperDiv;
}

/**
 * Appends a given question to the provided wrapper div.
 * If the question has sub-questions, it will append them as well.
 */
function appendQuestion(survey, question, wrapperDiv) {
  if (!shouldAppendQuestion(question)) {
    return null;
  }

  const questionDiv = createQuestionDiv(survey, question);
  const answerWrapperDiv = createElement("div", "answer-wrapper");
  let shouldAppend = true;

  if (question.templateElements) {
    question.templateElements.forEach((subQuestion) => {
      appendSubQuestion(survey, question, subQuestion, answerWrapperDiv);
    });
  } else if (question.items) {
    question.items.forEach((subQuestion) => {
      appendSubQuestion(survey, question, subQuestion, answerWrapperDiv);
    });
  } else {
    if (appendAnswer(survey, answerWrapperDiv, question) === null) {
      shouldAppend = false;
    }
  }

  if (shouldAppend) {
    questionDiv.appendChild(answerWrapperDiv);
    wrapperDiv.appendChild(questionDiv);
  }
}

/**
 * Determines if a question should be appended based on its properties.
 */
function shouldAppendQuestion(question) {
  if (EXCLUDED_KEYS.includes(question.name) || question.visible === false) {
    return false;
  }
  return true;
}

/**
 * Creates a `div` element for a given question. Depending on whether the question
 * has more items or not, it assigns different class names to the `div`.
 */
function createQuestionDiv(survey, question) {
  const isMultiQuestion = Boolean(question.items);
  const questionDiv = createElement(
    "div",
    isMultiQuestion ? "question-wrapper multi-question" : "question-wrapper single-question"
  );
  const questionTitle = createElement(isMultiQuestion ? "h3" : "h4");
  questionTitle.innerText = santizeQuestionTitle(survey, question.title);
  questionDiv.appendChild(questionTitle);
  return questionDiv;
}

function santizeQuestionTitle(survey, title) {
  if (title.includes("{loved_one_reference}")) {
    title = title.replace("{loved_one_reference}", survey.getValue("loved_one_reference"));
  } 
  if (title.includes("{was_is}")) {
    title = title.replace("{was_is}", survey.getValue("was_is"));
  } 
  if (title.includes("{at_the_time_of_their_death}")) {
    title = title.replace("{at_the_time_of_their_death}", survey.getValue("at_the_time_of_their_death"));
  } 
  if (title.includes("{was_is_capital}")) {
    title = title.replace("{was_is_capital}", survey.getValue("was_is_capital"));
  } 
  if (title.includes("{did_does_capital}")) {
    title = title.replace("{did_does_capital}", survey.getValue("did_does_capital"));
  }

  return title;
}

/**
 * Creates an edit button. When the button is clicked, it navigates to the provided `pageNumber`.
 */
function addEditButton(survey, wrapperDiv, pageNumber) {
  const editButton = createElement("button", "button--secondary");
  editButton.innerText = "Edit this section";
  editButton.addEventListener("click", () => {
    survey.currentPageNo = pageNumber;
  });

  const buttonWrapper = createElement("div", "edit-button-wrapper");
  buttonWrapper.appendChild(editButton);
  wrapperDiv.appendChild(buttonWrapper);
}

/**
 * Appends a sub-question to a given wrapperDiv based on provided survey and question data.
 */
function appendSubQuestion(survey, question, subQuestion, wrapperDiv) {
  if (EXCLUDED_SUB_KEYS.includes(subQuestion.name)) {
    return false;
  }

  const subQuestionDiv = createElement("div", "sub-question");

  if (subQuestion.title && !subQuestion.items) {
    const title = createElement("h4");
    title.innerText = santizeQuestionTitle(survey, subQuestion.title);
    subQuestionDiv.appendChild(title);
  }

  const isAnswerAppended =
    appendAnswer(survey, subQuestionDiv, question, subQuestion.name) !== null;
  if (isAnswerAppended) {
    wrapperDiv.appendChild(subQuestionDiv);
  }

  return isAnswerAppended;
}

/**
 * Retrieves an answer value from the survey, processes it based on its type,
 * and appends it to the provided div.
 */
function appendAnswer(survey, div, answerValue, subAnswerValue = null) {
  const surveyValue = survey.getValue(answerValue.name);
  let answerText = null;

  if (surveyValue) {
    answerText = subAnswerValue ? surveyValue[subAnswerValue] || null : surveyValue || null;

    if (Array.isArray(surveyValue) && !(typeof surveyValue[0] === "string")) {
      answerText = surveyValue.map((item) => item[subAnswerValue]);
    }

    if (typeof answerText === "string") {
      if (!answerText.includes("@")) {
        answerText = transformString(answerText);
      }
    }

    if (answerText && typeof answerText === "object") {
      answerText = handleObjects(div, answerText, answerValue, subAnswerValue);
    }

    const answer = createElement("span");
    answer.innerText = reformatIfDateString(answerText);
    div.appendChild(answer);
  }

  return answerText;
}

/**
 * Processes an `answerObject` based on certain conditions and appends relevant
 * details to a provided `div`.
 */
function handleObjects(div, answerObject, answerValue, subAnswerValue) {
  if (answerObject.length === 1 && answerObject[0] && Object.keys(answerObject[0]).length === 0) {
    return null;
  } else {
    const container = createElement("div");
    if (SPECIAL_KEYS.RELATIONSHIPS.includes(answerValue.name)) {
      getRelationDetails(div, container, answerObject, answerValue, subAnswerValue);
      return "";
    } else if (SPECIAL_KEYS.TABLES.includes(answerValue.name)) {
      getTableDetails(div, container, answerObject, answerValue);
      return "";
    }

    return answerObject;
  }
}

/**
 * Processes an array of data and appends the details to a provided `div`.
 */
function getRelationDetails(div, container, relationsArray, templateData, templateName) {
  if (typeof relationsArray[0] === "string") {
    appendObject(container, div, relationsArray);
  } else {
    for (let relationData of relationsArray) {
      let relationDetails = [];

      for (let relationKey in relationData) {
        let templateItems =
          templateData.templateElements.find((element) => element.name === templateName)?.items ||
          [];

        const itemTitle = templateItems.find((item) => item.name === relationKey)?.title;
        if (itemTitle) {
          relationDetails.push(`${itemTitle}: ${reformatIfDateString(relationData[relationKey])}`);
        }
      }

      appendObject(container, div, relationDetails, transformString(templateName));
    }
  }
}

/**
 * Processes `answerObject` to extract table details and appends them
 * to a provided `div`.
 */
function getTableDetails(div, container, answerObject, answerValue) {
  if (typeof answerObject[0] === "string") {
    appendObject(container, div, answerObject);
  } else {
    for (let row in answerObject) {
      const rowObject = answerValue.rows.find((item) => item.value === row);
      let details = [];
      let displayValue = answerObject[row].boolean ? "Yes" : "No";
      details.push(`${rowObject.text}: ${displayValue}`);

      appendObject(container, div, details);
    }
  }
}

/**
 * Appends a set of details to a provided `div`. If a title is provided,
 * it appends the title as well.
 */
function appendObject(container, div, details, title = null) {
  if (title) {
    const objectTitle = createElement("h4");
    objectTitle.innerText = title;
    container.appendChild(objectTitle);
  }

  const detailsWrapper = createElement("div", "details-wrapper");

  details.forEach((detail) => {
    const detailSpan = createElement("span");
    detailSpan.innerText = transformString(detail);
    detailsWrapper.appendChild(detailSpan);
  });

  container.appendChild(detailsWrapper);
  div.appendChild(container);
}

/**
 * Creates a new DOM element with an optional class name.
 */
function createElement(tag, className) {
  const element = document.createElement(tag);
  if (className) element.className = className;
  return element;
}

/**
 * Transforms a string by splitting it at underscores and capitalizing the
 * first letter of each word.
 */
function transformString(string) {
  return string
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

/**
 * Reformat date strings in the format "2000-01-01" to "01-01-2000"
 */
function reformatIfDateString(input) {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  if (datePattern.test(input)) {
    const parts = input.split("-");
    return parts[2] + "-" + parts[1] + "-" + parts[0];
  }

  return input;
}
